import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.development';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SignInDto, createPasswordDto, ForgotPasswordDTO, ResetPasswordDTO } from '../../dto/signin-dto';
import { CREATEUSERDTO } from '../../dto/userList-dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static userEmail = "ELSRT-USER-EMAIL";
  public static userFirstname = "ELSRT-USER-FIRSTNAME";
  public static userLastname = "ELSRT-USER-LASTNAME";
  public static userRole = "ELSRT-USER-ROLE";
  public static userState = "ELSRT-USER-STATE";
  public static userID = "ELSRT-USER-USERID";
  public static ID = "ELSRT-USER-ID";
  public static userToken = "ELSRT-USER-TOKEN";
  public static phoneNumber = "ELSRT-USER-PHONENUMBER";
  public static pushSetting = "ELSRT-USER-PUSH-SETTING";

  public path = environment.apiurl;

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }
  // Get Token value
  getToken() {
    return localStorage.getItem(AuthService.userToken);
  }

  // Get Authorization Token
  getAuthToken() {
    if (this.getToken() === null) {
      return '';
    } else {
      return 'Bearer ' + this.getToken();
    }
  }

  isLoggedIn() {
    const token = this.getToken();
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  // Sign In
  signInUser(signInDto: SignInDto) {
    const url = environment.apiurl + "login";
    return this.httpClient.post<any>(url, signInDto).pipe();
  }


  createPassword(createPasswordDto: createPasswordDto) {
    const url = environment.apiurl + "reset-password";
    return this.httpClient.post<any>(url, createPasswordDto).pipe();
  }

  activateuser(userId:string, activatonCode:string){ {
    const url = environment.apiurl + "users/verify";
    let query = {};
    if(userId && activatonCode){
      query = {
        activationCode: activatonCode,
        userId: userId
      }
    }
    return this.httpClient.post<any>(url, query).pipe();
  }
  }

  public setUserData(data: any){
    localStorage.setItem(AuthService.ID, data.user.id);
    localStorage.setItem(AuthService.userEmail, data.user.email);
    localStorage.setItem(AuthService.userFirstname, data.user.firstName);
    localStorage.setItem(AuthService.userLastname, data.user.lastName);
    localStorage.setItem(AuthService.userRole, data.user.role);
    localStorage.setItem(AuthService.userID, data.user.userId);
    localStorage.setItem(AuthService.userState, data.user.state);
    localStorage.setItem(AuthService.userToken, data.access_token);
    localStorage.setItem(AuthService.phoneNumber, data.user.phoneNumber);
    localStorage.setItem(AuthService.pushSetting, data.user.notification.webSetting);
  }

  public clearUserData(){
    localStorage.removeItem(AuthService.userEmail);
    localStorage.removeItem(AuthService.userFirstname);
    localStorage.removeItem(AuthService.userLastname);
    localStorage.removeItem(AuthService.userRole);
    localStorage.removeItem(AuthService.userID);
    localStorage.removeItem(AuthService.userState);
    localStorage.removeItem(AuthService.userToken);
    localStorage.removeItem(AuthService.phoneNumber);
    localStorage.removeItem(AuthService.ID);
    localStorage.removeItem(AuthService.pushSetting);
  }


  forgotPassword(data:ForgotPasswordDTO){
    const url = `${environment.apiurl}forgot-password`;
    return this.httpClient.post<ForgotPasswordDTO>(url, data).pipe();
  }

  resetPassword(data:ResetPasswordDTO){
    const url = `${environment.apiurl}reset-password`;
    return this.httpClient.post(url, data).pipe();

  }


  logout(){
    this.clearUserData()
  }
  public errorHandler(error: HttpErrorResponse) {
    // this.toastService.setError(this.errorMethod(error));
    console.log(this.errorMethod(error));
  }
  // Error Handler
  errorMethod(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (error?.error && error?.error === "Internal Server Error") {
        return error.error;
      }
      if (error?.error?.message) {
        if (error?.error?.message === "Token expired") {
          this.clearUserData();
          this.router.navigateByUrl("/signin", { replaceUrl: true });
        } else if (error?.error?.message == "Invalid token") {
          this.clearUserData();
          this.router.navigateByUrl("/signin", { replaceUrl: true });
        }
        if (error.error.message instanceof Array) {
          return error.error.message[0];
        } else {
          return error.error.message;
        }
      }
      if (error?.error?.errors[0].message) {
        return error.error.errors[0].message;
      }
    }
  }

}
