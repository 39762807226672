import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { BatchService } from './batch.service';
import saveAs from 'file-saver';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private batchService:BatchService, private authService:AuthService) { }
  public exportLoader = true;
  private readonly store$ = new BehaviorSubject<ApplicationState>(initialState);

  readonly exportData$ = this.store$.pipe(map((state) => state));
  setToastMessage(title: string, message: string, position = "rightBottom", timeCount = 5000) {
    this.store$.next({
      ...this.store$.value,
      title,
      loader: true,
      message,
      display: true,
      position,
      timeCount,
    });
    setTimeout(() => {
      this.clearStore();
    }, this.store$.value.timeCount);
  }

  exportSamplesReport(searchTerm:any){
    const message = "Sample report list will download shortly";
    this.store$.next({
      ...this.store$.value,
      title: "Exporting table",
      loader: true,
      message: "Please wait...",
      display: true,
      position: "",
      timeCount: 7200000,
    });
    this.batchService.exportBatchSamples(searchTerm).subscribe({
      next: (response: any) =>{
        let fileName = `Sample-Export.xlsx`;
        const contentDisposition = response.headers.get("Content-Disposition");
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches !== null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
        }
        const fileContent = response.body;
        const blob = new Blob([fileContent], {
          type: "application/octet-stream",
        });
        this.completeExport(message);
        saveAs(blob, fileName);
      },
      error:(error: any) => {
        this.failedExport('Something happened, please try again');
        this.errorHandler(error);
      }

    });
  }

  exportBatchesStatus(searchTerm:any, batchStatus:any, zoneFrom:any, zoneTo:any, stateFrom:any, stateTo:any, facilityFrom:any, facilityTo:any, startDate:any, endDate:any){
    const message = "Batch status list will download shortly";
    this.store$.next({
      ...this.store$.value,
      title: "Exporting table",
      loader: true,
      message: "Please wait...",
      display: true,
      position: "",
      timeCount: 7200000,
    });
    this.batchService.exportBatchStatus(searchTerm, batchStatus, zoneFrom, zoneTo, stateFrom, stateTo, facilityFrom, facilityTo, startDate, endDate).subscribe({
      next: (response: any) =>{
        let fileName = `Batch-Status-Export.xlsx`;
        const contentDisposition = response.headers.get("Content-Disposition");
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches !== null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
        }
        const fileContent = response.body;
        const blob = new Blob([fileContent], {
          type: "application/octet-stream",
        });
        this.completeExport(message);
        saveAs(blob, fileName);
      },
      error:(error: any) => {
        this.failedExport('Something happened, please try again');
        this.errorHandler(error);
      }

    });

  }

  exportBatchReport(searchTerm:any, zoneFrom:any, zoneTo:any, stateFrom:any, stateTo:any, facilityFrom:any, facilityTo:any, startDate:any, endDate:any){
    const message = "Batch report list will download shortly";
    this.store$.next({
      ...this.store$.value,
      title: "Exporting table",
      loader: true,
      message: "Please wait...",
      display: true,
      position: "",
      timeCount: 7200000,
    });
    this.batchService.exportBatchReport(searchTerm, zoneFrom, zoneTo, stateFrom, stateTo, facilityFrom, facilityTo, startDate, endDate).subscribe({
      next: (response: any) =>{
        let fileName = `Batch-Report-Export.xlsx`;
        const contentDisposition = response.headers.get("Content-Disposition");
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches !== null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
        }
        const fileContent = response.body;
        const blob = new Blob([fileContent], {
          type: "application/octet-stream",
        });
        this.completeExport(message);
        saveAs(blob, fileName);
      },
      error:(error: any) => {
        this.failedExport('Something happened, please try again');
        this.errorHandler(error);
      }

    });

  }

  exportDiseaseReport(searhTxt:string, startDate:any, endDate:any, zone:any, state:any){
    const msg = " Diseases report will download shortly";
    this.store$.next({
      ...this.store$.value,
      title: "Exporting table",
      loader: true,
      message: "Please wait...",
      display: true,
      position: "",
      timeCount: 7200000,
    });
    this.batchService.exportDiseases(searhTxt, startDate, endDate, zone, state).subscribe({
      next:(data:any)=>{
        let fileName = `Diseases-Report-Export.xlsx`;
        const contentDisposition = data.headers.get("Content-Disposition");
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches!== null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
        }
        const fileContent = data.body;
        const blob = new Blob([fileContent], {
          type: "application/octet-stream",
        });
        this.completeExport(msg);
        saveAs(blob, fileName);
      },
      error:(err: any) => {
        this.failedExport('Something happened, please try again');
        this.errorHandler(err);
      }
    })
  }
  completeExport(message:any){
    this.store$.next({
      ...this.store$.value,
      title: "Export Successful",
      loader: false,
      message,
      display: true,
      position: "rightBottom",
      timeCount: 5000,
    });
    setTimeout(() => {
      this.clearStore();
    }, this.store$.value.timeCount);
  }
  failedExport(message:any){
    this.store$.next({
      ...this.store$.value,
      title: "Export Failed",
      loader: false,
      message,
      display: true,
      position: "rightBottom",
      timeCount: 5000,
    });
    setTimeout(() => {
      this.clearStore();
    }, this.store$.value.timeCount);
  }

  clearStore() {
    this.store$.next({
      ...this.store$.value,
      message: "",
      display: false,
      title: "",
      position: "rightBottom",
      timeCount: 5000,
    });
  }



  errorHandler(error: HttpErrorResponse) {
    this.authService.errorHandler(error);
  }
}

export interface ApplicationState {
  title: string;
  loader: boolean;
  message: string;
  display: boolean;
  position: string;
  timeCount: number;
}
const initialState: ApplicationState = {
  title: "",
  loader: true,
  message: "",
  display: false,
  position: "rightBottom",
  timeCount: 5000,
};
