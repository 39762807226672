export enum GeneralEnum {
  central_admin = "central_admin",
  state_admin = "state_admin",
  rider = 'rider',
  central_logistics_admin = 'central_logistics_admin',
  state_logistics_admin = 'state_logistics_admin',
}

export enum centralRoleEnum {
  central_admin = 'Central Administrator',
  state_admin = 'State Administrator',
  central_logistics_admin = 'Central Logistics Administrator',
  state_logistics_admin = 'State Logistics Administrator',
  rider = 'Courier Agent',
}