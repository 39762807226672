import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth/auth.service';
import { ToastService } from '../toast';
import { BatchDTO } from '../dto/batch-detail.dto';
// import { getBatchDto } from '../dto/batch-dto';

@Injectable({
  providedIn: 'root'
})
export class BatchService {
  constructor(private httpClient: HttpClient, private authService: AuthService,
    private toastService:ToastService) {
  }

  public notify = new BehaviorSubject<any>("");
  public notifyAfterSwitch = new BehaviorSubject<any>("");

  notifyObservable$ = this.notify.asObservable();
  notifyObservable = this.notifyAfterSwitch.asObservable();

  public refreshData(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  public refreshDataAfterSwitch(data: any) {
    if (data) {
      this.notifyAfterSwitch.next(data);
    }
  }

  getBatches(searchData:string, page: any, limit:any, batchStatus:any, zoneFrom:any, zoneTo:any, stateFrom:any, stateTo:any, facilityFrom:any, facilityTo:any, startDate:any, endDate:any){
    const url = environment.apiurl + "batch/status-groups?";
    //const url = environment.apiurl + "batch?";
    let query = ""
    if(page){
      query += `page=${page}&`
    }else{
      query += `page=0&`
    }
    if(limit){
      query += `limit=${limit}&`
    }else{
      query += `limit=10&`
    }
    if(searchData){
      query += `batchId=${searchData}&`
    }
    if(batchStatus){
      query += `batchStatus=${batchStatus}&`
    }
    if(zoneFrom){
      query += `zoneFrom=${zoneFrom}&`
    }
    if(zoneTo){
      query += `zoneTo=${zoneTo}&`
    }
    if(stateFrom){
      query += `stateFrom=${stateFrom}&`
    }
    if(stateTo){
      query += `stateTo=${stateTo}&`
    }
    if(facilityFrom){
      query += `facilityFrom=${facilityFrom}&`
    }
    if(facilityTo){
      query += `facilityTo=${facilityTo}&`
    }
    if(startDate){
      query += `startDate=${startDate}&`
    }
    if(endDate){
      query += `endDate=${endDate}`
    }
    return this.httpClient.get<any>(url + query).pipe();
  }

  getBatchDetail(id:string, page:number=0, limit=2){
    const url = `${environment.apiurl}batch/?page=${page}&limit=${limit}&batchId=${id}`
    return this.httpClient.get<BatchDTO>(url).pipe();
  }

  getBatchSampleList(id:string, searchData:string, page=0, limit=10){
    let query = "";
    if(page){
      query += `page=${page}&`
    }else{
      query += `page=0&`
    }
    if(limit){
      query+=`limit=${limit}&`
    }else{
      query+=`limit=10&`
    }if(id){
      query+=`batchId=${id}&`
    }
    if(searchData){
      query+=`sampleId=${searchData}&`
    }
    const url = `${environment.apiurl}batch/samples?`
    return this.httpClient.get(url+query).pipe()
  }
  
  getDeliveredBatches(
    searchData:string, 
    page: any, 
    limit:any, 
    batchStatus:string,
    zoneFrom:any, 
    zoneTo:any, 
    stateFrom:any, 
    stateTo:any, 
    facilityFrom:any, 
    facilityTo:any, 
    startDate:any, 
    endDate:any,
    sortBy='updatedAt'
  ){
    const url = environment.apiurl + "batch?";
    let query = ""
    if(batchStatus){
      query += `batchStatus=${batchStatus}&`
    }
    if(page){
      query += `page=${page}&`
    }else{
      query += `page=0&`
    }
    if(limit){
      query += `limit=${limit}&`
    }else{
      query += `limit=10&`
    }
    if(searchData){
      query += `batchId=${searchData}&`
    }
    if(stateFrom){
      query+=`stateFrom=${stateFrom}&`
    }
    if(stateTo){
      query+=`stateTo=${stateTo}&`
    }
    if(zoneFrom){
      query+=`zoneFrom=${zoneFrom}&`
    }
    if(zoneTo){
      query+=`zoneTo=${zoneTo}&`
    }
    if(facilityFrom){
      query+=`facilityFrom=${facilityFrom}&`
    }
    if(facilityTo){
      query+=`facilityTo=${facilityTo}&`
    }
    if(startDate){
      query+=`tripEndedAtFrom=${startDate}&`
    }
    if(endDate){
      query+=`tripEndedAtTo=${endDate}&`
    }
    if(endDate){
      query+=`sortBy=${sortBy}`
    }
    return this.httpClient.get<any>(url + query).pipe();
  }

  getAllBatchSamples(
    searchData:string, 
    page: any, 
    limit:any,
    zone:any, 
    state:any, 
    facility:any
  ){
    const url = environment.apiurl + "batch/samples?";
    let query = ""
    if(page){
      query += `page=${page}&`
    }else{
      query += `page=0&`
    }
    if(limit){
      query += `limit=${limit}&`
    }else{
      query += `limit=10&`
    }
    if(zone ){
      query += `zoneFrom=${zone}&`
    }
    if(state ){
      query += `stateFrom=${state}&`
    }
    if(facility ){
      query += `facilityFrom=${facility}&`
    }
    if(searchData ){
      query += `searchText=${searchData}&`
    }
    return this.httpClient.get<any>(url + query).pipe();
  }

  exportBatchSamples(searchData:string){
    const uri = environment.apiurl + "batch/samples/export?";
    let query = ""
    if(searchData){
      query += `sampleId=${searchData}`
    }
    const url = uri + query;
    return this.httpClient.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      responseType: "blob",
      observe: "response",
    }).pipe();
  }

  exportBatchStatus(searchData:string, batchStatus:any, zoneFrom:any, zoneTo:any, stateFrom:any, stateTo:any, facilityFrom:any, facilityTo:any, startDate:any, endDate:any){
    const uri = environment.apiurl + "batch/export?";
    let query = ""
    if(searchData){
      query += `batchId=${searchData}`
    }
    if(batchStatus){
      query += `batchStatus=${batchStatus}&`
    }
    if(zoneFrom){
      query += `zoneFrom=${zoneFrom}&`
    }
    if(zoneTo){
      query += `zoneTo=${zoneTo}&`
    }
    if(stateFrom){
      query += `stateFrom=${stateFrom}&`
    }
    if(stateTo){
      query += `stateTo=${stateTo}&`
    }
    if(facilityFrom){
      query += `facilityFrom=${facilityFrom}&`
    }
    if(facilityTo){
      query += `facilityTo=${facilityTo}&`
    }
    if(startDate){
      query += `startDate=${startDate}&`
    }
    if(endDate){
      query += `endDate=${endDate}`
    }
    const url = uri + query;
    return this.httpClient.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      responseType: "blob",
      observe: "response",
    }).pipe();
  }

  exportBatchReport( searchData:string, zoneFrom:any, zoneTo:any, stateFrom:any, stateTo:any, facilityFrom:any, facilityTo:any, startDate:any, endDate:any){
    const uri = environment.apiurl + "/batch/report/export?";
    let query = ""
    // if(batchStatus){
    //   query += `batchStatus=${batchStatus}&`
    // }
    if(searchData){
      query += `batchId=${searchData}`
    }
    if(zoneFrom){
      query += `zoneFrom=${zoneFrom}&`
    }
    if(zoneTo){
      query += `zoneTo=${zoneTo}&`
    }
    if(stateFrom){
      query += `stateFrom=${stateFrom}&`
    }
    if(stateTo){
      query += `stateTo=${stateTo}&`
    }
    if(facilityFrom){
      query += `facilityFrom=${facilityFrom}&`
    }
    if(facilityTo){
      query += `facilityTo=${facilityTo}&`
    }
    if(startDate){
      query += `startDate=${startDate}&`
    }
    if(endDate){
      query += `endDate=${endDate}`
    }
    const url = uri + query;
    return this.httpClient.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      responseType: "blob",
      observe: "response",
    }).pipe();
  }

  getFacility(zone:string, stateName:string){
    const url = environment.apiurl + "locations/facilities?";
    let limit = 10000;
    let page = 0;
    let query = ""
    if(page){
      query += `page=${page}&`
    }else{
      query += `page=0&`
    }
    if(limit){
      query += `limit=${limit}&`
    }else{
      query += `limit=10000&`
    }
    if(stateName){
      query += `stateName=${stateName}&`
    }
    if(zone){
      query += `zone=${zone}`
    }
    return this.httpClient.get<any>(url + query).pipe();
  }

  getActivityLog(batchId:string, page=0, limit=20){
    let query = "";
    if(page){
      query+=`page=${page}&`
    }else{
      query+=`page=0&`
    }
    if(limit){
      query+=`limit=${limit}&`
    }else{
      query+=`limit=20&`
    }
    if(batchId){
      query+=`searchText=${batchId}&`
    }
   

    const url = `${environment.apiurl}logs?`;
    return this.httpClient.get(url+query).pipe();
  }

  getDiseaseStats(name:string, zone:string, state:string, startDate:any, endDate:any){
    let query = "";
    if(zone){
      query+=`zone=${zone}&`
    }
    if(state){
      query+=`state=${state}&`
    }
    if(startDate){
      query+=`startDate=${startDate}&`
    }
    if(endDate){
      query+=`endDate=${endDate}&`
    }
    const url = `${environment.apiurl}batch/diseases/${name}/summary?`;
    return this.httpClient.get(url+query).pipe();

  }


  getDiseaseList(
    searchTxt:string, 
    page =0, 
    limit=20, 
    startDate:any,
    endDate:any,
    zone:string,
    stateName?:string,
  ){
    let query =""
    const url = `${environment.apiurl}batch/diseases?`;
    if(page){
      query += `page=${page}&`;
    }else{
      query+=`page=0&`;
    }
    if(limit){
      query+=`limit=${limit}&`;
    }else{
      query+=`limit=20&`;
    }
    if(searchTxt){
      query +=`searchTerm=${searchTxt}&`;
    }
    if(startDate){
      query+=`startDate=${startDate}&`;
    }
    if(endDate){
      query+=`endDate=${endDate}&`;
    }
    if(zone){
      query+=`zone=${zone.toLocaleUpperCase()}&`;
    }
    if(stateName){
      query+=`state=${stateName}&`;
    }
    return this.httpClient.get(url+query).pipe();
  }

  getDiseaseDetail(search:any, batchId:any, page=0, limit=20){
    const url = `${environment.apiurl}batch/samples?`
    let query = "";
    if(search){
      query +=`disease=${search}&`;
    }
    if(batchId){
      query +=`batchId=${batchId}&`
    }
    if(page){
      query+=`page=${page}&`
    }
    else{
      query+=`page=0&`;
    }
    if(limit){
      query+=`limit=${limit}&`
    }else{
      query+=`limit=20&`;
    }
    return this.httpClient.get(url+query).pipe();
  }

  exportDiseases(searchTxt:string, startDate:any, endDate:any, zone:string, state:any){
    const url = `${environment.apiurl}batch/diseases/export?`;
    let query = ""
    if(searchTxt){
      query+=`searchTerm=${searchTxt}&`;
    }
    if(startDate){
      query+=`startDate=${startDate}&`;
    }
    if(endDate){
      query+=`endDate=${endDate}&`;
    }
    if(zone){
      query+=`zone=${zone.toLocaleUpperCase()}&`
    }
    if(state){
      query+=`state=${state.toLocaleUpperCase()}&`
    }
    return this.httpClient.get(url+query, {
      headers:{
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      responseType: "blob",
      observe: "response",
    }).pipe()
  }


  getOngoingBatch(batchStatus:string, page:any, limit:any,  startDate:any, endDate:any, zone:string, state:string, disease:any, searchTxt:string){
    let url = `${environment.apiurl}/batch?`;
    let query = "";
    if(batchStatus){
      query+=`batchStatus=${batchStatus}&`;
    }if(startDate){
      query+=`startDate=${startDate}&`;
    }if(endDate){
      query+=`endDate=${endDate}&`;
    }
    if(zone){
      query+=`zoneFrom=${zone.toLocaleUpperCase()}&`;
    }
    if(state){
      query+=`stateFrom=${state.toLocaleUpperCase()}&`;
    }
    if(page){
      query+=`page=${page}&`;
    }else{
      query+=`page=0&`;
    }
    if(limit){
      query+=`limit=${limit}&`;
    }else{
      query+=`limit=0&`;
    }
    return this.httpClient.get(url+query).pipe();
  }


  public errorHandler(error: HttpErrorResponse) {
    // return throwError(error);
    return this.toastService.setErrorMessage(
      this.authService.errorMethod(error),
      4000,
      'topCenter'
    );
  }
}