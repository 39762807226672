import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  
  @Input() type = "PRIMARY";
  @Input() disableButton = false;
  @Input() widthsize?: string;
  @Input() label = 'Button';
  @Input() imgUrl = "";

}