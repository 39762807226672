import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from "rxjs/operators";

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth/auth.service';
import { ToastService } from '../toast';
import { changePasswordDto, CREATEUSERDTO, USERLISTDto } from '../dto/userList-dto';




@Injectable({
  providedIn: 'root'
})
export class UserListService {
  constructor(
    private httpClient: HttpClient, 
    private authService: AuthService,
    private toastService:ToastService) {
  }


  getUserList(searchData:string, status:string, role:string, page:any, limit:any ){
    const url = environment.apiurl + "users?";
    let query = ""
    if(page){
      query += `page=${page}&`
    }else{
      query += `page=0&`
    }
    if(limit){
      query += `limit=${limit}&`
    }else{
      query += `limit=10&`
    }
    if(searchData){
      query += `searchTerm=${searchData}&`
    }
    if(status){
      query += `status=${status}&`
    }
    if(role){
      query += `role=${role}&`
    }
    return this.httpClient.get<any>(url + query).pipe();
  }
  getLocation(searchData:string, page: any, limit:any ){
    const url = environment.apiurl + "locations?";
    let query = ""
    if(page){
      query += `page=${page}&`
    }else{
      query += `page=0&`
    }
    if(limit){
      query += `limit=${limit}&`
    }else{
      query += `limit=10&`
    }
    if(searchData){
      query = `page=0&limit=10&`
      query += `searchText=${searchData}`
    }
    return this.httpClient.get<any>(url + query).pipe();
  }

  deleteuseraccount(userData:any) {
    let user ={email:userData?.email,password:userData?.password}
    const url = environment.apiurl + "users";
    return this.httpClient.delete<any>(url,{body:user}).pipe();
  }

  CreateUser(CREATEUSERDTO:{}){
    const url = environment.apiurl + "users";
    return this.httpClient.post<any>(url, CREATEUSERDTO).pipe();
  }
  updateUser(updateUSERDTO:{}, userid:any){
    const url = environment.apiurl + `users/${userid}`;
    return this.httpClient.patch<any>(url, updateUSERDTO).pipe();
  }
  updateUserStatus(action:any, userId:string){
    const url = environment.apiurl + "users/"+ userId;
    return this.httpClient.patch<any>(url, action).pipe();
  }

  changePassword(updatePasswordDTO:changePasswordDto){
    const url = environment.apiurl + "users/change-password";
    return this.httpClient.put<any>(url, updatePasswordDTO).pipe();
  }

  updatePushSetting(userID:string, webSetting:any){
    const url = environment.apiurl + `users/${userID}/notification`;
    return this.httpClient.patch<any>(url, webSetting).pipe();
  }

  cancelInvite(userId:any){
    const url = environment.apiurl + `users/${userId}/cancel-invite`;
    return this.httpClient.patch<any>(url, {userId:userId}).pipe();
  }

  resendInvite(resendDTO:{}){
    const url = environment.apiurl + "users/re-invite";
    return this.httpClient.post<any>(url, resendDTO).pipe();
  }

  public errorHandler(error: HttpErrorResponse) {
    // return throwError(error);
    return this.toastService.setErrorMessage(
      this.authService.errorMethod(error),
      4000,
      'topCenter'
    );
  }
}
