import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {

  @Input() inpage: boolean = false;

@Input() width:string = "50px";
@Input() height:string = "50px";
@Input() margin:string = "40vh 43%";

}
