import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs';
import { fromEvent, merge, of, Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly stores = new BehaviorSubject<AppState>(initialState);
  readonly toastData = this.stores.pipe(map((state) => state));
  public networkStatus: Subscription = Subscription.EMPTY;

  constructor() {}

  setSuccessMessage(title:string="", message: string, timeCount = 5000, position = 'rightBottom') {
    this.stores.next({
      ...this.stores.value,
      title,
      message,
      display: true,
      timeCount,
      position,
    });
    setTimeout(() => {
      this.clearMessage();
    }, this.stores.value.timeCount);
  }

  setPushMessage(title:string="", message: string, timeCount = 5000, position = 'rightBottom', push = 'push') {
    this.stores.next({
      ...this.stores.value,
      title,
      message,
      push,
      display: true,
      timeCount,
      position,
    });
    setTimeout(() => {
      this.clearMessage();
    }, this.stores.value.timeCount);
  }

  setErrorMessage(message: string, timeCount = 5000, position = 'rightBottom') {
    this.stores.next({
      ...this.stores.value,
      title: 'Error Occured',
      message,
      display: true,
      timeCount,
      position,
    });
    setTimeout(() => {
      this.clearMessage();
    }, this.stores.value.timeCount);
  }

  setWarningMesssage(
    title:string="",
    message: string,
    timeCount = 5000,
    position = 'rightBottom'
  ) {
    this.stores.next({
      ...this.stores.value,
      message,
      title: 'Warning',
      display: true,
      timeCount,
      position,
    });
    setTimeout(() => {
      this.clearMessage();
    }, this.stores.value.timeCount);
  }

  clearMessage() {
    this.stores.next({
      ...this.stores.value,
      title: '',
      message: '',
      display: true,
      position: 'rightBottom',
      timeCount: 5000,
    });
  }


  ngOnDestroy(): void {
    this.networkStatus.unsubscribe();
  }
}

export interface AppState {
  position: string;
  display: boolean;
  title?: string;
  push?: string;
  timeCount: number;
  message: string;
}

const initialState: AppState = {
  display: false,
  title: '',
  push: '',
  message: '',
  timeCount: 5000,
  position: 'rightBottom',
};
