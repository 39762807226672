let accept_icon = "../assets/icons/accept-icon.svg";
let activate_circle ="../assets/icons/activate-circle.svg";
let activated_circle ="../assets/icons/activated-circle.svg";
let active_audit_icon = "../assets/icons/active-audit-icon.svg";
let active_box ="../assets/icons/active-box.svg";
let active_sample = "../assets/icons/active-sample-icon.svg";
let airplain = "../assets/icons/airplane.svg";
let alert_circle ="../assets/icons/alert-circle.svg";
let apple_store = "../assets/icons/apple-store.svg";
let arr_rite = "../assets/icons/arr-rite.svg";
let arrow_circle_right_send_requrest = "../assets/icons/arrow-circle-right-send-request.svg";
let arrow_circle_left = "../assets/icons/arrow-circle-right.svg";
let arrow_down = "../assets/icons/arrow-down.svg";
let arrow_left = "../assets/icons/arrow-left.svg";
let arrow_right_blue = "../assets/icons/arrow-right-blue.svg";
let arrow_right = "../assets/icons/arrow-right.svg";
let back_icon = "../assets/icons/back-icon.svg";
let badge_icon = "../assets/icons/badge-icon.svg";
let bactch_box_black = "../assets/icons/batch-box-black.svg";
let batch_box_blue = "../assets/icons/batch-box-blue.svg";
let batch_box = "../assets/icons/batch-box.svg";
let batch_icon = "../assets/icons/batch-icon.svg";
let blue_box = "../assets/icons/blue-box.svg";
let blue_building = "../assets/icons/blue-buliding.svg";
let blue_calender = "../assets/icons/blue-calendar.svg";
let blue_check = "../assets/icons/blue-check.svg";
let blue_filter_date = "../assets/icons/blue-filter-date.svg";
let blue_flag = "../assets/icons/blue-flag.svg";
let blue_splash = "../assets/icons/blue-flash.svg";
let blue_plane = "../assets/icons/blue-plane.svg";
let blue_profile = "../assets/icons/blue-profile.svg";
let blue_truck = "../assets/icons/blue-truck.svg";
let blue_x = "../assets/icons/blue-x.svg";
let bold_x = "../assets/icons/bold-x.svg";
let box_white = "../assets/icons/box-white.svg";

let call_icom = "../assets/icons/call-icon.svg";
let card_design = "../assets/icons/card-design.svg";
let check ="../assets/icons/check.svg";
let chevron_down ="../assets/icons/chevron-down.svg";
let clock_icon = "../assets/icons/clock-icon.svg";
let close_icon = "../assets/icons/close-circle.svg";
let cloud_arrow_down = "../assets/icons/cloud-arrow-down.svg";
let cloud_cross = "../assets/icons/cloud-cross.svg";
let date_filter_icon = "../assets/icons/date-filter-icon.svg";
let date_picker = "../assets/icons/date-picker.svg";
let deactivate_circle_slash = "../assets/icons/deactivate-circle-slash.svg";
let decline_icon = "../assets/icons/decline-icon.svg";
let delivered_design = "../assets/icons/delivered-design.svg";
let delivred_icon = "../assets/icons/delivered-icon.svg";
let dest_airplane = "../assets/icons/dest-airplane.svg";
let disable_notification = "../assets/icons/disable-notification-bing.svg";
let disease_icon = "../assets/icons/disease-icon.svg";
let document_text_black = "../assets/icons/document-text-black.svg";
let document_text_blue = "../assets/icons/document-text-blue.svg";
let document_text ="../assets/icons/document-text.svg";
let dot_line = "../assets/icons/dot-line.svg";
let edit ="../assets/icons/edit.svg";
let ellipse = "../assets/icons/Ellipse 2.svg";
let empty = "../assets/icons/empty-box.svg";
let empty_search_normal = "../assets/icons/empty-search-normal.svg";
let anable_notification = "../assets/icons/enable-notification.svg";
let exclamation_circle_dark ="../assets/icons/exclaimation-circle-dark.svg";
let exclamation_circle = "../assets/icons/exclaimation-circle.svg";
let export1 ="../assets/icons/export.svg";
let eye_slash = "../assets/icons/eye-slash.svg";
let eye = "../assets/icons/eye.svg";
let filter_date = "../assets/icons/filter-date.svg";
let filter_desease = "../assets/icons/filter-disease.svg";
let filter_location = "../assets/icons/filter-location.svg";
let filter_role = "../assets/icons/filter-role.svg";
let filter_status ="../assets/icons/filter-status.svg";
let flag="../assets/icons/flag.svg";
let flight_arr_icon = "../assets/icons/flight_arr_icon.svg";
let flight_dep_icon = "../assets/icons/flight_dep_icon.svg";
let gps_location = "../assets/icons/gps-location.svg";
let gray_warning_icon = "../assets/icons/gray-warning-icon.svg";
let handoff_note = "../assets/icons/hand-off-note.svg";
let handoff_design = "../assets/icons/handoff-design.svg";
let handoff_icon = "../assets/icons/handoff-icon.svg";
let hover_audit_icon = "../assets/icons/hover-audit-icon.svg";
let hover_badge_icon = "../assets/icons/hover-badge-icon.svg";
let hover_sample_icon = "../assets/icons/hover-ssample-icon.svg";
let hr_line = "../assets/icons/hr-line.svg";
let inactive_box = "../assets/icons/in-active-box.svg";
let in_active_sample ="../assets/icons/in-active-sample.svg";
let in_transit_icon = "../assets/icons/in-transit-icon.svg";
let inactive_audit_icon = "../assets/icons/inactive-audit-icon.svg";
let intransit_design = "../assets/icons/intransit-design.svg";
let linear_edit = "../assets/icons/linear-edit.svg";
let linear_eye = "../assets/icons/linear-eye.svg";
let location_blue = "../assets/icons/location-blue.svg";
let location_dark = "../assets/icons/lock-dark.svg";
let log_initiated_icon = "../assets/icons/log-initatied-icon.svg";
let log_intransite_icon = "../assets/icons/log-intransit-icon.svg";
let log_user = "../assets/icons/log-user.svg";
let logout_icon = "../assets/icons/logout-icon.svg";
let mail = "../assets/icons/mail.svg";
let mail2 = "../assets/icons/mail2.svg";
let map_close_icon = "../assets/icons/map-close-icon.svg";
let menu = "../assets/icons/menu.svg";
let modal_flight_icon="../assets/icons/modal-flight-icon.svg";
let modal_location = '../assets/icons/modal-location.svg'
let more = "../assets/icons/more.svg";
let nav_arrow_left = "../assets/icons/nav-arrow-left.svg";
let nav_arrow_right = "../assets/icons/nav-arrow-right.svg";
let notification_bing = "../assets/icons/notification-bing.svg";
let notification_empty = "../assets/icons/notification-empt-state-icon.svg";
let notification_hover = "../assets/icons/notification-hover-icon.svg";
let notification_icon = "../assets/icons/notification-icon.svg";
let notification_settings = "../assets/icons/notification-setting.svg"
let notification_timer = "../assets/icons/notification-timer.svg";
let origin_building = "../assets/icons/origin-buliding.svg";
let over_lay="../assets/icons/overlay.svg";
let overview_black = "../assets/icons/overview-black.svg";
let overview_blue = "../assets/icons/overview-blue.svg";
let overview = "../assets/icons/overview.svg";
let page_not_found_icon = "../assets/icons/page-not-found-icon.svg";
let pending_icon = "../assets/icons/pending-icon.svg";
let people_black = "../assets/icons/people-black.svg";
let people_blue = "../assets/icons/people-blue.svg";
let people = "../assets/icons/people.svg";
let person = "../assets/icons/person.svg";
let phone_download = "../assets/icons/phone-download.svg";
let phone_icon = "../assets/icons/phone-icon.svg";
let play_store = "../assets/icons/play-store.svg";
let print_icon = "../assets/icons/print-icon.svg";
let profile_icon = "../assets/icons/profile-icon.svg";
let profile_tick = "../assets/icons/profile-tick.svg";
let password_icon = "../assets/icons/pswd-icon.svg";
let read_icon = "../assets/icons/read-icon.svg";
let rectangle1_bg = "../assets/icons/Rectangle1-bg.svg";
let rectangle2_bg = "../assets/icons/Rectangle2-bg.svg";
let rectangle3_bg = "../assets/icons/Rectangle3-bg.svg";
let rectangle4_bg = "../assets/icons/Rectangle4-bg.svg";
let rite_arr = "../assets/icons/arr-rite.svg";
let search_normal = "../assets/icons/search-normal.svg";
let settings_icon = "../assets/icons/setting-icon.svg";
let shield = "../assets/icons/shield.svg";
let signout_icon = "../assets/icons/signout-icon.svg";
let slash = "../assets/icons/slash.svg";
let succes_box = "../assets/icons/sucess-box.svg";
let table_eye = "../assets/icons/table-eye.svg";
let takeoff_airplane = "../assets/icons/take-off-airplane.svg";
let temp_icon = "../assets/icons/temp-icon.svg";
let toast_check_icon = "../assets/icons/toast-check-icon.svg";
let transit_beacon = "../assets/icons/transit-beacon.svg";
let trip_icon = "../assets/icons/trip-icon.svg";
let truck_fast = "../assets/icons/truck-fast.svg";
let u_angle_left = "../assets/icons/u-angle-left-b.svg";
let u_angle_right = "../assets/icons/u_angle-right-b.svg";
let user_add = "../assets/icons/user-add.svg";
let user_icon = "../assets/icons/user-icon.svg";
let user_icon2 = "../assets/icons/user-icon2.svg";
let warning_box = "../assets/icons/warning-box.svg";
let warning = "../assets/icons/warning.svg";
let white_delivered_icon = "../assets/icons/white-delivered-icon.svg";
let white_user_icon = "../assets/icons/white-user-icon.svg";
let x_with_background = "../assets/icons/x-with-background.svg";
let x = "../assets/icons/x.svg";
let box = "../assets/icons/box.svg"


let outline_arrange_circle = "../assets/icons/outline/arrange-circle.svg";
let outline_arrange_circle_2 = "../assets/icons/outline/arrange-circle-2.svg";
let outline_arrage_square_2 = "../assets/icons/outline/arrange-square-2.svg";
let outline_arrage_suare = "../assets/icons/outline/arrange-square.svg";
let outline_arrow_2 = "../assets/icons/outline/arrow-2.svg";
let outline_arrow_3 = "../assets/icons/outline/arrow-3.svg";
let outline_arrow_angle_down = "../assets/icons/outline/arrow-angle-down.svg";
let outline_arrow_angle_left = "../assets/icons/outline/arrow-angle-left.svg"
let outline_arrow_angle_right = "../assets/icons/outline/arrow-angle-right.svg"
let outline_arrow_angle_up = "../assets/icons/outline/arrow-angle-up.svg"
let outline_arrow_circle_down = "../assets/icons/outline/arrow-circle-down.svg";
let outline_arrow_circle_point_down = "../assets/icons/outline/arrow-circle-point-down.svg";
let outline_undo = "../assets/icons/outline/undo.svg";
let outline_send ="../assets/icons/outline/send.svg";
let outline_send_square = "../assets/icons/outline/send-square.svg";
let outline_send_square_2 = "../assets/icons/outline/send-square-2.svg";
let outline_rotate_right = "../assets/icons/outline/send-square-2.svg";
let outline_right_left = "../assets/icons/outline/rotate-left.svg";
let outline_repeat = "../assets/icons/outline/repeat.svg";
let outline_repeat_circle = "../assets/icons/outline/repeat-circle.svg";
let outline_refresh = "../assets/icons/outline/refresh.svg";
let outline_refresh_square_2 = "../assets/icons/outline/refresh-square-2.svg";
let outline_refresh_square_right = "../assets/icons/outline/refresh-right-square.svg";
let outline_refresh_square_left = "../assets/icons/outline/refresh-left-square.svg";
let outline_refresh_circle  = "../assets/icons/outline/refresh-circle.svg";
let outline_refresh_2 = "../assets/icons/outline/refresh-2.svg";
let outline_redo = "../assets/icons/outline/redo.svg";
let outline_recieved = "../assets/icons/outline/received.svg";
let outline_recieve_square = "../assets/icons/outline/receive-square.svg";
let outline_recieve_square_2 = "../assets/icons/outline/receive-square-2.svg";
let outline_logout_left = "../assets/icons/outline/logout-left.svg"
let outline_login = "../assets/icons/outline/login.svg";
let outline_login_right = "../assets/icons/outline/login-right.svg";
let outline_import_icon = "../assets/icons/outline/import.svg";
let outline_import_square = "../assets/icons/outline/import-square.svg";
let outline_import_down_left = "../assets/icons/outline/import-down-left.svg";
let outline_frame = "../assets/icons/outline/frame.svg";
let outline_forward_square = "../assets/icons/outline/forward-square.svg";
let outline_export_icon = "../assets/icons/outline/export.svg";
let outline_export_top_right = "../assets/icons/outline/export-top-right.svg";
let outline_export_square = "../assets/icons/outline/export-square.svg";
let outline_export_circle = "../assets/icons/outline/export-circle.svg";
let outline_ellipse_2 = "../assets/icons/outline/Ellipse 2.svg";
let outline_convert = "../assets/icons/outline/convert.svg";
let outline_back_square = "../assets/icons/outline/back-square.svg";
let outline_arrow_up = "../assets/icons/outline/arrow-up.svg";
let outline_arrow_swap = "../assets/icons/outline/arrow-swap.svg";
let outline_arrow_swap_horizontal = "../assets/icons/outline/arrow-swap-horizontal.svg";
let outline_arrow_square_up = "../assets/icons/outline/arrow-square-up.svg";
let outline_arrow_square_right = "../assets/icons/outline/arrow-square-right.svg";
let outline_arrow_square_down = "../assets/icons/outline/arrow-square-down.svg";
let outline_arrow_square_left = "../assets/icons/outline/arrow-square-left.svg";
let outline_arrow_play_up = "../assets/icons/outline/arrow-play-up.svg"
let outline_arrow_play_right = "../assets/icons/outline/arrow-play-right.svg"
let outline_arrow_play_left = "../assets/icons/outline/arrow-play-left.svg"
let outline_arrow_play_bottom = "../assets/icons/outline/arrow-play-bottom.svg";
let outline_arrow_circle_up = "../assets/icons/outline/arrow-circle-up.svg"
let outline_arrow_circle_point_up = "../assets/icons/outline/arrow-circle-point-up.svg"
let outline_arrow_circle_point_left = "../assets/icons/outline/arrow-circle-point-lfft.svg"
let outline_arrow_circle_point_right = "../assets/icons/outline/arrow-circle-point-right.svg"
let calender = "../assets/calender.svg";
let hunburger = "../assets/icons/bold/humburger.svg";
let arrow_circle_right_white = "../assets/icons/bold/arrow-circle-right-white.svg";
let filter = "../assets/icons/filter.svg";
let arrow_grey_up = "../assets/icons/bold/arrow-grey-up.svg";
let arrow_grey_down = "../assets/icons/bold/arrow-grey-down.svg";
let sorter_up = "../assets/icons/bold/sorter-up.svg"
let sorter_down = "../assets/icons/bold/sorter-down.svg";
let checkbox_blue = "../assets/icons/checkbox-blue.svg";
let decheck_checkbox = "../assets/icons/Decheck-CheckBox.svg";
let check_box_empty_state = "../assets/icons/EmptyStatecheckbox.svg";

export const icons = {
    checkbox_blue:checkbox_blue,
    decheck_checkbox:decheck_checkbox,
    check_box_empty_state:check_box_empty_state,
    arrow_grey_down:arrow_grey_down,
    arrow_grey_up:arrow_grey_up,
    sorter_up:sorter_up,
    sorter_down:sorter_down,
    filter:filter,
    box:box,
    arrow_circle_right_white:arrow_circle_right_white,
    accept_icon:accept_icon,
    activate_circle:activate_circle,
    activated_circle:activated_circle,
    active_audit_icon:active_audit_icon,
    active_box:active_box,
    active_sample:active_sample,
    airplain:airplain,
    alert_circle:alert_circle,
    apple_store:apple_store,
    arr_rite:arr_rite,
    arrow_circle_right_send_requrest:arrow_circle_right_send_requrest,
    arrow_circle_left:arrow_circle_left,
    arrow_down:arrow_down,
    arrow_left:arrow_left,
    arrow_right_blue:arrow_right_blue,
    back_icon:back_icon,
    arrow_right:arrow_right,
    badge_icon:badge_icon,
    bactch_box_black:bactch_box_black,
    batch_box_blue:batch_box_blue,
    batch_box:batch_box,
    batch_icon:batch_icon,
    blue_box:blue_box,
    blue_building:blue_building,
    blue_calender:blue_calender,
    blue_check:blue_check,
    blue_filter_date:blue_filter_date,
    blue_flag:blue_flag,
    blue_splash:blue_splash,
    blue_plane:blue_plane,
    blue_profile:blue_profile,
    blue_truck:blue_truck,
    blue_x:blue_x,
    bold_x:bold_x,
    box_white:box_white,
    call_icom:call_icom,
    card_design:card_design,
    check:check,
    chevron_down:chevron_down,
    clock_icon:clock_icon,
    close_icon:close_icon,
    cloud_arrow_down:cloud_arrow_down,
    cloud_cross:cloud_cross,
    date_filter_icon:date_filter_icon,
    date_picker:date_picker,
    deactivate_circle_slash:deactivate_circle_slash,
    decline_icon:decline_icon,
    delivered_design:delivered_design,
    delivred_icon:delivred_icon,
    dest_airplane:dest_airplane,
    disable_notification: disable_notification,
    disease_icon:disease_icon,
    document_text_black:document_text_black,
    document_text_blue:document_text_blue,
    document_text:document_text,
    dot_line:dot_line,
    edit:edit,
    ellipse:ellipse,
    empty:empty,
    empty_search_normal:empty_search_normal,
    anable_notification:anable_notification,
    exclamation_circle_dark:exclamation_circle_dark,
    exclamation_circle:exclamation_circle,
    export1:export1,
    eye_slash:eye_slash,
    eye:eye,
    filter_date:filter_date,
    filter_desease:filter_desease,
    filter_location:filter_location,
    filter_role:filter_role,
    filter_status:filter_status,
    flag:flag,
    flight_arr_icon:flight_arr_icon,
    flight_dep_icon:flight_dep_icon,
    gps_location:gps_location,
    gray_warning_icon:gray_warning_icon,
    handoff_icon:handoff_icon,
    handoff_design:handoff_design,
    handoff_note:handoff_note,
    hover_audit_icon:hover_audit_icon,
    hover_badge_icon:hover_badge_icon,
    hover_sample_icon:hover_sample_icon,
    hr_line:hr_line,
    inactive_audit_icon:inactive_audit_icon,
    in_transit_icon:in_transit_icon,
    in_active_sample:in_active_sample,
    inactive_box:inactive_box,
    intransit_design:intransit_design,
    linear_edit:linear_edit,
    linear_eye:linear_eye,
    location_blue:location_blue,
    location_dark:location_dark,
    log_initiated_icon:log_initiated_icon,
    log_intransite_icon:log_intransite_icon,
    log_user:log_user,
    logout_icon:logout_icon,
    mail:mail,
    mail2:mail2,
    map_close_icon:map_close_icon,
    menu:menu,
    modal_flight_icon:modal_flight_icon,
    modal_location:modal_location,
    more:more,
    nav_arrow_left:nav_arrow_left,
    nav_arrow_right:nav_arrow_right,
    notification_bing:notification_bing,
    notification_empty:notification_empty,
    notification_hover:notification_hover,
    notification_icon:notification_icon,
    notification_settings:notification_settings,
    notification_timer:notification_timer,
    origin:origin,
    origin_building:origin_building,
    over_lay:over_lay,overview:overview,
    overview_blue:overview_blue,
    overview_black:overview_black,
    page_not_found_icon:page_not_found_icon,
    pending_icon:pending_icon,
    people_black:people_black,
    people_blue:people_blue,
    people:people,
    person:person,
    phone_download:phone_download,
    phone_icon:phone_icon,
    play_store:play_store,
    print_icon:print_icon,
    profile_icon:profile_icon,
    profile_tick:profile_tick,
    password_icon:password_icon,
    read_icon:read_icon,
    rectangle1_bg:rectangle1_bg,
    rectangle2_bg:rectangle2_bg,
    rectangle3_bg:rectangle3_bg,
    rectangle4_bg:rectangle4_bg,
    rite_arr:rite_arr,
    search_normal:search_normal,
    settings_icon:settings_icon,
    shield:shield,
    signout_icon:signout_icon,
    slash:slash,
    succes_box:succes_box,
    takeoff_airplane:takeoff_airplane,
    table_eye:table_eye,
    temp_icon:temp_icon,
    toast_check_icon:toast_check_icon,
    transit_beacon:transit_beacon,
    trip_icon:trip_icon,
    truck_fast:truck_fast,
    u_angle_left:u_angle_left,
    u_angle_right:u_angle_right,
    user_add:user_add,
    user_icon:user_icon,
    user_icon2:user_icon2,
    warning_box:warning_box,
    warning:warning,
    white_delivered_icon:white_delivered_icon,
    white_user_icon:white_user_icon,
    x_with_background:x_with_background,
    x:x,
    outline_arrage_square_2:outline_arrage_square_2,
    outline_arrage_suare:outline_arrage_suare,
    outline_arrange_circle:outline_arrange_circle,
    outline_arrange_circle_2:outline_arrange_circle_2,
    outline_arrow_2:outline_arrow_2,
    outline_arrow_3:outline_arrow_3,
    outline_arrow_angle_down:outline_arrow_angle_down,
    outline_arrow_angle_left:outline_arrow_angle_left,
    outline_arrow_angle_right:outline_arrow_angle_right,
    outline_arrow_angle_up:outline_arrow_angle_up,
    outline_arrow_circle_down:outline_arrow_circle_down,
    outline_arrow_circle_point_down:outline_arrow_circle_point_down,
    outline_arrow_circle_point_left:outline_arrow_circle_point_left,
    outline_arrow_circle_point_right:outline_arrow_circle_point_right,
    outline_arrow_circle_point_up:outline_arrow_circle_point_up,
    outline_arrow_circle_up:outline_arrow_circle_up,
    outline_arrow_play_bottom:outline_arrow_play_bottom,
    outline_arrow_play_left:outline_arrow_play_left,
    outline_arrow_play_right:outline_arrow_play_right,
    outline_arrow_play_up:outline_arrow_play_up,
    outline_arrow_square_left:outline_arrow_square_left,
    outline_arrow_square_down:outline_arrow_square_down,
    outline_arrow_square_right:outline_arrow_square_right,
    outline_arrow_square_up:outline_arrow_square_up,
    outline_arrow_swap_horizontal:outline_arrow_swap_horizontal,
    outline_arrow_up:outline_arrow_up,
    outline_arrow_swap:outline_arrow_swap,
    outline_back_square:outline_back_square,
    outline_convert:outline_convert,
    outline_ellipse_2:outline_ellipse_2,
    outline_export_circle:outline_export_circle,
    outline_export_square:outline_export_square,
    outline_export_top_right:outline_export_top_right,
    outline_export_icon:outline_export_icon,
    outline_forward_square:outline_forward_square,
    outline_frame:outline_frame,
    outline_import_down_left:outline_import_down_left,
    outline_import_icon:outline_import_icon,
    outline_import_square:outline_import_square,
    outline_login_right:outline_login_right,
    outline_login:outline_login,
    outline_logout_left:outline_logout_left,
    outline_recieve_square:outline_recieve_square,
    outline_recieve_square_2:outline_recieve_square_2,
    outline_recieved:outline_recieved,
    outline_redo:outline_redo,
    outline_refresh:outline_refresh,
    outline_refresh_2:outline_refresh_2,
    outline_refresh_circle:outline_refresh_circle,
    outline_refresh_square_2:outline_refresh_square_2,
    outline_refresh_square_left:outline_refresh_square_left,
    outline_refresh_square_right:outline_refresh_square_right,
    outline_repeat:outline_repeat,
    outline_repeat_circle:outline_repeat_circle,
    outline_rotate_right:outline_rotate_right,
    outline_right_left:outline_right_left,
    outline_send:outline_send,
    outline_send_square:outline_send_square,
    outline_send_square_2:outline_send_square_2,
    outline_undo:outline_undo,
    calender:calender,
    hunburger:hunburger,
}

