import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';

@Component({
  selector: 'app-button-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button-wrapper.component.html',
  styleUrl: './button-wrapper.component.scss'
})
export class ButtonWrapperComponent {
  
  @Input() type = "PRIMARY";
  @Input() disableButton = false;
  @Input() widthsize?: string;
  @Input() label = 'Button';

}