<div class="drop-down" #customSelect [tabindex]="tabValue" (focusout)="_onFocusOut($event)">
  <div class="view" (click)="showDropdown()">
    <ng-content select="#drop-down-title"></ng-content>
  </div>
  <div class="extra">
    <ng-content select="#body"></ng-content>
  </div>
  <div class="content" *ngIf="showList">
    <ng-content select="#drop-down-options"> </ng-content>
  </div>
</div>
