import { ChangeDetectionStrategy, Component, ElementRef, Input, SimpleChanges, ViewChild, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-select-field',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
})
export class SelectFieldComponent  implements OnInit {
  @ViewChild("selectField") selectField!: ElementRef;
  showList = false;
  @Input() tabValue = 1;
  @Input() levelDeep = 5;
  @Input() closeList = false;
  public showDropDownList = false;

  
  constructor() { }

  ngOnInit() {}

  ngOnChanges(change: SimpleChanges) {
    if (change["closeDropdown"]?.currentValue) {
      this.showDropdown();
    }
    if (change["closeList"]) {
      this.showList = false;
    }
  }

  showDropdown() {
    this.showList = !this.showList;
  }

  _onFocusOut(e: any) {
    if (!this.isDescendant(this.selectField.nativeElement, e.relatedTarget)) {
      this.showList = false;
    }
  }

  isDescendant(pParent: any, pChild: HTMLElement) {
    try {
      let count = 0;
      while (pChild !== null && count < this.levelDeep) {
        if (pChild === pParent) {
          return true;
        } else {
          pChild = pChild.parentNode as HTMLElement;
        }
        count++;
      }
    } catch (e) {
      console.warn("isDescendant ", e);
    }
    return false;
  }
}
