let apple_store = "../assets/images/apple-store.png";
let blue_plane = "../assets/images/blue-plane.png";
let certified = "../assets/images/certified.png";
let design_img = "../assets/images/deisgn-img.svg";
let elsrt_illus1 = "../assets/images/elsrt+illus1.png";
let elsrt_illus2 = "../assets/images/elsrt+illus2.png";
let elsrt_illus = "../assets/images/elsrt+illus3.png";
let eye ="../assets/images/eye.png";
let favicon = "../assets/images/favicon.png";
let flag = "../assets/images/flag.png";
let homeview1 = "../assets/images/homeview1.svg";
let location_black = "../assets/images/location-black.png";
let location_blue = "../assets/images/location-blue.png";
let location1 ="../assets/images/location.png";
let logo ="../assets/images/logo.png";
let play_store = "../assets/images/play-store.png";

 export  const  Images = {
    apple_store:apple_store,
    blue_plane:blue_plane,
    certified:certified,
    design_img:design_img,
    elsrt_illus1:elsrt_illus1,
    elsrt_illus2:elsrt_illus2,
    elsrt_illus:elsrt_illus,
    eye:eye,
    favicon:favicon,
    flag:flag,
    homeview1:homeview1,
    location1:location1,
    location_black:location_black,
    location_blue:location_blue,
    logo:logo,
    play_store:play_store
}

