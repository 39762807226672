@if (type=='email' ) {
  <div class="field-div">
    <input 
      [type]="type" 
      [placeholder]="placeholder" 
      [style.height.px]="height" 
      [style.width.px]="width" 
      [(ngModel)]="formValue"
      (ngModelChange)="outPutData()"
      class="form-field"
      [formControl]="control"
      [ngClass]="control.invalid && control.dirty && control.touched ? 'error' : ''"
    >
  </div>
  @if(control.invalid && control.touched){
  <ng-container *ngFor="let err of control.errors | keyvalue">
    @if(control.errors){
    @if(control.errors["email"] && err.key === 'email'){
    <small class="errorMessage">
      <span>
        <img [src]="icons.alert_circle" alt="">
      </span>
      <span>
        {{ errorMessages[err.key] }}
      </span>
    </small>
    } @if(control.errors["pattern"] && err.key === 'pattern' && !control.errors["email"]){
    <small class="errorMessage">
      <span>
        <img [src]="icons.alert_circle" alt="">
      </span>
      <span>
        {{ errorMessages[err.key] }}
      </span>
    </small>
    }
    @if(control.errors["required"] && err.key === 'required'){
    <small class="errorMessage">
      <span>
        <img [src]="icons.alert_circle" alt="">
      </span>
      <span>
        {{ errorMessages[err.key] }}
      </span>
    </small>
    }
    @if(control.errors["minlength"] && err.key === 'minlength'){
    <small class="errorMessage">
      <span>
        <img [src]="icons.alert_circle" alt="">
      </span>
      <span>
        {{ errorMessages['requiredLength'] }}
      </span>
    </small>
    }
    }
  </ng-container>
  }
  <!-- <ng-container *ngIf="control.invalid && control.dirty && control.touched">
    <span *ngFor="let err of control.errors | keyvalue" class="errorMessage">
      <img src="../../../../assets/icons/alert-circle 2.svg" alt="" /> {{ errorMessages[err.key] }}
      {{
        errorMessageName == "password" && !errorMessages[err.key]
          ? "Password must be greater than or equal to 6 "
          : errorMessages[err.key] === "Password does not match" ||
            errorMessages[err.key] === "Password must be greater than or equal to 6"
          ? ""
          : errorMessageName
      }}
    </span>
  </ng-container> -->
}@else if (type=='textarea') {
  <div  class="field-div">
    <textarea 
      [placeholder]="placeholder" 
      [style.height.px]="height" 
      [style.width.px]="width" 
      [(ngModel)]="formValue"
      (ngModelChange)="outPutData()"
      class="form-field"
      [cols]="cols"
      [rows]="row"
    ></textarea>
  </div>
}
@else if (type=='text' && !passwordToggler ) {
  <div class="field-div" >
    <input 
      [type]="type" 
      [placeholder]="placeholder" 
      [style.height.px]="height" 
      [style.width.px]="width" 
      [(ngModel)]="formValue"
      (ngModelChange)="outPutData()"
      class="form-field"
    >
  </div>
}@else if (passwordToggler || type=='password' ) {
  <form action="" autocomplete="false">
  <div class="field-div" >
    <input 
      [type]="type" 
      [placeholder]="placeholder" 
      [style.height.px]="height" 
      [style.width.px]="width" 
      [(ngModel)]="formValue"
      autocomplete="off"
      (ngModelChange)="outPutData()"
      class="form-field"
      name="placeholder"
      [formControl]="control"
      [ngClass]="control.invalid && control.dirty && control.touched ? 'error' : ''"
      >
    @if (passwordToggler && showPassword) {
      <img [src]="icons.eye_slash"
        class="icon" 
        (click)="togglePswd()"
        alt=""
      >
    }
    @if(!showPassword){
      <img [src]="icons.eye" 
        class="icon" 
        (click)="togglePswd()" alt=""
      >
    }
  </div>
</form>
  @if(control.invalid && control.touched){
  <ng-container *ngFor="let err of control.errors | keyvalue">
    @if(control.errors){
    @if(control.errors["email"] && err.key === 'email'){
    <small class="errorMessage">
      <span>
        <img [src]="icons.alert_circle" alt="">
      </span>
      <span>
        {{ errorMessages[err.key] }}
      </span>
    </small>
    } @if(control.errors["pattern"] && err.key === 'pattern' && !control.errors["email"]){
    <small class="errorMessage">
      <span>
        <img [src]="icons.alert_circle" alt="">
      </span>
      <span>
        {{ errorMessages[err.key] }}
      </span>
    </small>
    }
    @if(control.errors["required"] && err.key === 'required'){
    <small class="errorMessage">
      <span>
        <img [src]="icons.alert_circle" alt="">
      </span>
      <span>
        {{ errorMessages[err.key] }}
      </span>
    </small>
    }
    @if(control.errors["minlength"] && err.key === 'minlength'){
    <small class="errorMessage">
      <span>
        <img [src]="icons.alert_circle" alt="">
      </span>
      <span>
        {{ errorMessages['requiredLength'] }}
      </span>
    </small>
    }
    }
  </ng-container>
  }
  <!-- <ng-container *ngIf="control.invalid && control.dirty && control.touched">
    <span *ngFor="let err of control.errors | keyvalue" class="errorMessage">
      <img src="../../../../assets/icons/alert-circle 2.svg" alt="" /> {{ errorMessages[err.key] }}
      {{
        errorMessageName == "password" && !errorMessages[err.key]
          ? "Password must be greater than or equal to 6 "
          : errorMessages[err.key] === "Password does not match" ||
            errorMessages[err.key] === "Password must be greater than or equal to 6"
          ? ""
          : errorMessageName
      }}
    </span>
  </ng-container> -->
}

@else if (type=='select') {
  <div class="field-div" >
      <app-select-field class="drop-down-field" [closeList]="showDropDown">
      <div class="selection-dropdown" id="drop-down-title">
        <div class="item-display" [class]="selectedItem == '' ? 'item-display ' : 'dark'">
          {{selectedItem ? selectedItem : 'Select' }}
        </div>
        <img [src]="icons.arrow_down" alt="" />
      </div>
      <div id="drop-down-options" class="item-list">
        <div class="service-select"></div>
        @for (item of dropDownList; track $index) {
          <div class="service-list" 
          (click)="setItem(item)"
          >
            <div>{{ item }}</div>
          </div>
        }
      </div>
    </app-select-field>
  </div>
}
@else if (type=='date' ) {
  <div class="date-field-div" >
    <app-calendar class="date-field" (dateValue)="dateInput($event)"></app-calendar>
  </div>
}
@else if (type==='search') {
  <div class="field-div search-div" >
    <img [src]="icons.search_normal" class="search-icon" alt="">
    <!-- <form action="" autocomplete="false"> -->
    <input 
      [type]="type"
      [placeholder]="placeholder" 
      autocomplete="off"
      [style.height.px]="height" 
      [style.width.px]="width" 
      [(ngModel)]="formValue"
      (ngModelChange)="outPutData()"
      class="form-field search-field"
    >
  <!-- </form> -->
    @if (formValue) {
      <img class="clear-search" (click)="clearSearch()" [src]="icons.close_icon" alt="">
    }
  </div>
}