import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "app-drop-down-custom",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./drop-down-custom.component.html",
  styleUrls: ["./drop-down-custom.component.scss"],
})
export class DropDownCustomComponent {
  @ViewChild("customSelect") customSelect!: ElementRef;
  showList = false;
  @Input() tabValue = 1;
  @Input() levelDeep = 5;
  @Input() closeList = false;
  @Input() disable = false;
  @Output() overlayCheck = new EventEmitter<any>() ;

  showDropdown() {
    if (this.disable) {
      return;
    } else {
      this.showList = !this.showList;
    }
  }
  ngOnChanges(change: SimpleChanges) {
    if (change["closeDropdown"]?.currentValue) {
      this.showDropdown();
    }
    if (change["closeList"]) {
      this.showList = false;
    }
  }

  _onFocusOut(e: any) {
    if (!this.isDescendant(this.customSelect.nativeElement, e.relatedTarget)) {
      this.showList = false;
      this.overlayCheck.emit(false);
    } else {
      e.stopPropagation();
    }
  }

  isDescendant(pParent: any, pChild: HTMLElement) {
    try {
      let count = 0;
      while (pChild !== null && count < this.levelDeep) {
        if (pChild === pParent) {
          return true;
        } else {
          pChild = pChild.parentNode as HTMLElement;
        }
        count++;
      }
    } catch (e) {
      console.warn("isDescendant ", e);
    }
    return false;
  }
}
